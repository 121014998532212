import * as React from 'react'

import { graphql } from 'gatsby'

import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { Footer, Header, Layout, SEO } from '@components'

type DataProps = {
  readonly data: {
    file: {
      childImageSharp: {
        gatsbyImageData: GatsbyImageProps
      }
    }
    allContentfulQuote: {
      edges: Array<{ node: { text: string } }>
    }
  }
}

const Head = (props: any) => <SEO {...props} />

/**
 * @param data.file                  Background image for 404 page.
 * @param data.allContentfulQuote    All possible quotes for 404 error.
 */
const NotFoundPage: React.FC<DataProps> = ({ data }) => {
  const quotes = data.allContentfulQuote.edges
  const quotePicked = quotes[new Date().getDay() % quotes.length]

  return (
    <Layout>
      <Head title="404" />
      <div className="background-image__wrapper">
        <GatsbyImage
          className="background-image"
          backgroundColor="#021523"
          loading="eager"
          // @ts-ignore
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Maurice's Lair"
        />
      </div>
      <div className="tint" />
      <Header />
      <section className="hero">
        <div
          className="hero__body"
          style={{ margin: 'calc(50vh - 110px - 29.9px) auto' }}
        >
          <div className="error">
            <h1 className="error__header">404</h1>
            <h2 className="error__subheader">{quotePicked.node.text}</h2>
            <p className="error__signature">~ Maurice</p>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 720
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    allContentfulQuote {
      edges {
        node {
          text
        }
      }
    }
  }
`
